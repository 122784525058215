<template>
    <v-card v-bind="$attrs" class="pa-4 d-flex">
        <div class="d-flex flex-column mr-auto">
            <div class="headline">{{ headlineDisplay }}</div>
            <div v-if="!!userStoredContact.company" class="pt-1"
                >{{ userStoredContact.company }}
            </div>

            <div v-if="!!userStoredContact.email" class="pt-3">
                <span class="pr-4">
                    <v-icon>{{ mdiEmail }}</v-icon></span
                >
                {{ userStoredContact.email }}
            </div>
            <div v-if="!!userStoredContact.phone">
                <span class="pr-4">
                    <v-icon>{{ mdiPhone }}</v-icon></span
                >{{ userStoredContact.phone }}
            </div>
        </div>
        <div>
            <v-icon v-if="edit" color="primary" @click="emitEdit">
                {{ mdiPencil }}
            </v-icon>
        </div>
    </v-card>
</template>

<script>
import { mdiEmail, mdiPhone, mdiPencil } from '@mdi/js'
export default {
    name: `AcountUserInfoMinimized`,
    inheritAttrs: false,
    props: {
        userStoredContact: {
            type: Object,
            required: true
        },
        sub: {
            type: String,
            required: true
        },
        edit: {
            type: Boolean
        }
    },
    data() {
        return {
            mdiEmail,
            mdiPencil,
            mdiPhone
        }
    },
    computed: {
        headlineDisplay() {
            if (this.userStoredContact) {
                if (
                    this.userStoredContact.firstName &&
                    this.userStoredContact.lastName
                )
                    return (
                        this.userStoredContact.firstName +
                        ` ` +
                        this.userStoredContact.lastName
                    )
                if (this.userStoredContact.email)
                    return this.userStoredContact.email
                else return `User Profile`
            } else return `No user profile found`
        }
    },
    methods: {
        emitEdit() {
            this.$emit(`edit`)
        }
    }
}
</script>
