import axios from 'axios'

export const changePassword = ({ userId, password, newPassword }) =>
    axios({
        method: `post`,
        url: `${process.env.VUE_APP_AUTH_API}/action/change-password`,
        data: {
            userId,
            password,
            newPassword
        }
    })
