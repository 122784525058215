<template>
    <v-card v-bind="$attrs">
        <v-card-title class="headline"> Change Password </v-card-title>

        <div>
            <div
                v-if="state === 0"
                class="justify-end mb-4"
                :class="
                    $vuetify.breakpoint.smAndDown
                        ? 'flex-column mx-4'
                        : 'd-flex mr-4'
                "
            >
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                    v-bind="$attrs"
                    color="primary"
                    @click="advanceState"
                >
                    Start
                </v-btn>
            </div>
            <edit-password
                v-else-if="state === 1"
                v-bind="$attrs"
                :user-id="userId"
                @change-success="success"
                @cancel="state = 0"
            />
            <div v-else-if="state === 2" class="pa-6">
                <v-icon large color="green darken-2">{{ mdiCheck }}</v-icon>
                Your password has been successfully updated.
            </div>
        </div>
    </v-card>
</template>

<script>
import EditPassword from './edit_password'
import { mdiCheck } from '@mdi/js'
export default {
    name: `ResetPassword`,
    components: {
        EditPassword
    },
    props: {
        userId: {
            type: String,
            required: true
        }
    },

    data: () => ({
        state: 0,
        mdiCheck
    }),

    methods: {
        success() {
            this.state = 2
            const self = this
            setTimeout(() => {
                self.state = 0
            }, 7000)
        },
        advanceState() {
            if (this.state !== 2) this.state++
        }
    }
}
</script>
