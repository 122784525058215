<template>
    <v-form v-model="valid">
        <v-card v-bind="$attrs" :disabled="false" @keypress.enter="save">
            <div>
                <div class="v-card__title mb-0 pb-0">
                    <div class="mr-auto">Edit {{ label }}</div>
                    <div>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on, attrs }">
                                <v-icon v-bind="attrs" @click="cancel" v-on="on"
                                    >{{ mdiClose }}
                                </v-icon>
                            </template>
                            <span>Cancel</span>
                        </v-tooltip>
                    </div>
                </div>
                <div class="v-card__title my-0 py-0">
                    <slot name="copy-from-billing" />
                </div>
            </div>
            <v-card-text class="mt-0">
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="firstName"
                            v-model="updatedAddress.firstName"
                            v-bind="$attrs"
                            label="First Name"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="lastName"
                            v-model="updatedAddress.lastName"
                            v-bind="$attrs"
                            label="Last Name"
                        />
                    </v-col>
                </v-row>
                <v-text-field
                    ref="company"
                    v-model="updatedAddress.company"
                    v-bind="$attrs"
                    label="Company"
                />

                <v-text-field
                    ref="address-line1"
                    v-model="updatedAddress.line1"
                    v-bind="$attrs"
                    label="Address Line 1"
                />
                <v-text-field
                    ref="address-line2"
                    v-model="updatedAddress.line2"
                    v-bind="$attrs"
                    label="Address Line 2"
                />
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            ref="country"
                            v-model="updatedAddress.country"
                            v-bind="$attrs"
                            :items="countries"
                            item-text="name"
                            item-value="countryCode"
                            label="Country"
                            placeholder="Country"
                            @change="countryChanged"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-autocomplete
                            ref="state"
                            v-model="updatedAddress.state"
                            v-bind="$attrs"
                            item-text="name"
                            item-value="stateCode"
                            :loading="statesLoading"
                            :disabled="statesLoading || !updatedAddress.country"
                            :items="stateProvinces"
                            label="State/Province/Region"
                            placeholder="State"
                        />
                    </v-col>
                </v-row>
                <v-row>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="city"
                            v-model="updatedAddress.city"
                            v-bind="$attrs"
                            label="City"
                        />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field
                            ref="zip"
                            v-model="updatedAddress.postalCode"
                            v-bind="$attrs"
                            label="ZIP / Postal Code"
                        />
                    </v-col>
                </v-row>
            </v-card-text>
            <div
                class="justify-end mb-4"
                :class="
                    $vuetify.breakpoint.smAndDown
                        ? 'flex-column mx-4'
                        : 'd-flex mr-4'
                "
            >
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    text
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                    v-bind="$attrs"
                    :disabled="false"
                    @click="cancel"
                    >Cancel</v-btn
                >
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                    color="primary"
                    text
                    :disabled="!valid"
                    v-bind="$attrs"
                    @click="save"
                >
                    Save
                </v-btn>
            </div>
        </v-card>
    </v-form>
</template>

<script>
import { mdiClose } from '@mdi/js'

export default {
    name: `AccountEditAddress`,
    props: {
        copyFrom: {
            type: [Object, null],
            default: null
        },
        address: {
            type: Object,
            required: true
        },
        label: {
            type: String,
            default: () => `Address`
        }
    },
    data() {
        return {
            valid: null,
            statesLoading: false,
            citiesLoading: false,
            mdiClose,
            updatedAddress: Object.assign(
                {},
                { country: `US`, ...this.address }
            ),
            previousAddress: {}
        }
    },

    computed: {
        countries() {
            return this.$store.getters[`location/getCachedCountries`]
        },

        cities() {
            return this.$store.getters[`location/getCachedCities`]({
                countryCode: this.updatedAddress.country,
                stateCode: this.updatedAddress.state
            })
        },
        stateProvinces() {
            return this.$store.getters[`location/getCachedStates`]({
                countryCode: this.updatedAddress.country
            })
        }
    },

    watch: {
        copyFrom(v) {
            if (v) {
                this.previousAddress = Object.assign({}, this.updatedAddress)
                this.updatedAddress = Object.assign({}, this.copyFrom)
            } else {
                this.updatedAddress = this.previousAddress
            }
        }
    },

    async mounted() {
        await this.$store.dispatch(`location/cacheStates`, {
            countryCode: this.updatedAddress.country
        })
    },

    methods: {
        async countryChanged(v) {
            if (v) {
                this.statesLoading = true
                try {
                    await this.$store.dispatch(`location/cacheStates`, {
                        countryCode: v
                    })
                    this.statesLoading = false
                } catch {
                    this.statesLoading = false
                }
            } else this.updatedAddress.state = ``
        },

        async getCities(event) {
            this.citiesLoading = true
            try {
                const [countryCode, stateCode] = event
                await this.$store.dispatch(`location/cacheCities`, {
                    countryCode,
                    stateCode
                })
            } catch {
                this.citiesLoading = false
            } finally {
                this.citiesLoading = false
            }
        },

        save() {
            this.$emit(`save`, {
                city: this.updatedAddress.city || ``,
                company: this.updatedAddress.company || ``,
                country: this.updatedAddress.country || ``,
                firstName: this.updatedAddress.firstName || ``,
                lastName: this.updatedAddress.lastName || ``,
                line1: this.updatedAddress.line1 || ``,
                line2: this.updatedAddress.line2 || ``,
                postalCode: this.updatedAddress.postalCode || ``,
                state: this.updatedAddress.state || ``
            })
        },

        cancel() {
            this.$emit(`cancel`)
        }
    }
}
</script>
