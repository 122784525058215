<template>
    <v-form ref="form" v-model="isValid" @submit.prevent @submit="submit">
        <v-card flat>
            <v-card-text>
                <v-text-field
                    v-model="oldPW"
                    outlined
                    dense
                    :append-icon="showPassword1 ? mdiEye : mdiEyeOff"
                    :type="showPassword1 ? 'text' : 'password'"
                    label="Old Password"
                    required
                    :rules="[(v) => !!v || 'Cannot be empty']"
                    @click:append="showPassword1 = !showPassword1"
                />
                <v-text-field
                    ref="pw1"
                    v-model="newPW1"
                    dense
                    :append-icon="showPassword2 ? mdiEye : mdiEyeOff"
                    :type="showPassword2 ? 'text' : 'password'"
                    outlined
                    :rules="pw1Rules"
                    label="New Password"
                    required
                    @click:append="showPassword2 = !showPassword2"
                />
                <v-text-field
                    ref="pw2"
                    v-model="newPW2"
                    dense
                    :append-icon="showPassword3 ? mdiEye : mdiEyeOff"
                    :type="showPassword3 ? 'text' : 'password'"
                    outlined
                    :rules="pw2Rules"
                    label="Re-Enter New Password"
                    required
                    @click:append="showPassword3 = !showPassword3"
                />
            </v-card-text>
            <div
                v-if="errorMessage"
                class="justify-end mt-0 pt-0"
                :class="
                    $vuetify.breakpoint.smAndDown
                        ? 'flex-column mx-4'
                        : 'd-flex mr-4'
                "
            >
                <v-alert
                    :block="$vuetify.breakpoint.smAndDown"
                    type="error"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                >
                    {{ errorMessage }}
                </v-alert></div
            >
            <div
                class="justify-end mb-4"
                :class="
                    $vuetify.breakpoint.smAndDown
                        ? 'flex-column mx-4'
                        : 'd-flex mr-4'
                "
            >
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    text
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                    v-bind="$attrs"
                    @click="cancel"
                    >Cancel</v-btn
                >
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    :class="
                        !$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'
                    "
                    color="primary"
                    text
                    v-bind="$attrs"
                    :disabled="!formValid"
                    type="submit"
                >
                    Submit
                </v-btn>
            </div>
        </v-card>
    </v-form>
</template>

<script>
import { changePassword } from '../../../auth/api.js'
import { mdiEye, mdiEyeOff } from '@mdi/js'
export default {
    name: `ResetPassword`,
    props: {
        userId: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            mdiEye,
            mdiEyeOff,
            showPassword1: false,
            showPassword2: false,
            showPassword3: false,
            oldPW: ``,
            newPW1: ``,
            newPW2: ``,
            errorMessage: ``,
            isValid: null,
            pw1Rules: [
                (v) => v.length >= 8 || `Must be minimum 8 characters`,
                (v) => {
                    if (!this.newPW2) return true
                    else return v === this.newPW2 || `Passwords must match`
                }
            ],
            pw2Rules: [
                (v) =>
                    (v && v.length >= 8 && v === this.newPW1) ||
                    `Passwords must match`
            ]
        }
    },
    computed: {
        formValid() {
            return this.isValid
        }
    },
    watch: {
        newPW1(v) {
            if (this.newPW2 && v.length >= 8)
                this.$nextTick(() => {
                    this.$refs.form.validate()
                })
        },
        newPW2(v) {
            if (this.newPW1 && v.length >= 8)
                this.$nextTick(() => {
                    this.$refs.form.validate()
                })
        }
    },
    methods: {
        async submit() {
            try {
                await changePassword({
                    userId: this.userId,
                    password: this.oldPW,
                    newPassword: this.newPW1
                })

                this.$emit(`change-success`)
            } catch (error) {
                const { status } = error || {}
                switch (status) {
                    case 500:
                        this.errorMessage = `A Server error has occured`
                        break
                    case 401:
                        this.errorMessage = `Password is incorrect.`
                        break
                    default:
                        this.errorMessage = `An unknown error occured`
                }
            }
        },

        cancel() {
            this.$emit(`cancel`)
        }
    }
}
</script>
