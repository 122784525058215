<template>
    <v-container id="account-bg">
        <base-hero-simple dark class="mb-2">
            <base-router-links :items="links" />
        </base-hero-simple>
        <v-container>
            <v-row>
                <v-col cols="12" md="6">
                    <user-info
                        :user-stored-contact="$auth.user"
                        outlined
                        :sub="$auth.user.sub"
                        dense
                        @save="saveInfo"
                    />
                    <br />
                    <reset-password v-if="!federatedAcct" outlined dense :user-id="$auth.user.sub" />
                </v-col>
                <v-col cols="12" md="6">
                    <address-form
                        v-model="oidcBilling"
                        outlined
                        dense
                        label="Billing"
                        @save="(data) => saveInfo({ address: { billing: data } })"
                    />
                    <br />
                    <address-form
                        v-model="oidcShipping"
                        outlined
                        dense
                        :copy-from="copyBilling ? oidcBilling : null"
                        :disabled="copyBilling"
                        label="Shipping"
                        @save="(data) => saveInfo({ address: { shipping: data } })"
                        @cancel="resetCopyBilling"
                    >
                        <template v-slot:copy-from-billing>
                            <v-checkbox v-model="copyBilling" hide-details dense :label="`Same as billing`" />
                        </template>
                    </address-form>
                </v-col>
            </v-row>
            <v-snackbar v-model="snackbar" color="red" :timeout="15000" top>
                {{ errorMessage }}

                <template v-slot:action="{ attrs }">
                    <v-btn dark text v-bind="attrs" @click="snackbar = false"> Close </v-btn>
                </template>
            </v-snackbar>
        </v-container>
    </v-container>
</template>

<script>
import UserInfo from '@/components/account/user_info'
import AddressForm from '@/components/account/address'
import ResetPassword from '@/components/account/reset_password'
import { navLinks } from './navigation.js'
import { postAccountDetails } from '@/api'
export default {
    metaInfo: { title: `Your Account`, subTitle: `Account Settings` },
    name: `AccountSettings`,
    components: {
        UserInfo,
        AddressForm,
        ResetPassword
    },
    data() {
        return {
            links: navLinks,
            billingEdit: false,
            shippingEdit: false,
            copyBilling: true,
            errorMessage: null,
            snackbar: false
        }
    },
    computed: {
        federatedAcct() {
            const sub = this.$auth.user.sub
            return !sub.match(
                /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        },
        oidcBilling() {
            return this.$auth.user.address ? this.$auth.user.address.billing : {}
        },
        oidcShipping() {
            return this.$auth.user.address ? this.$auth.user.address.shipping : {}
        }
    },

    mounted() {
        this.resetCopyBilling()
    },

    methods: {
        resetCopyBilling() {
            if (this.$auth.user.address && typeof this.$auth.user.address.copyBilling !== `undefined`) {
                this.copyBilling = this.$auth.user.address.copyBilling
            }
        },
        async saveInfo(data) {
            if (data.address) {
                data.address.copyBilling = this.copyBilling
                if (data.address.shipping && this.copyBilling) {
                    data.address.shipping = this.oidcBilling
                }

                if (data.address.billing && this.copyBilling) {
                    data.address.shipping = data.address.billing
                }
            }

            try {
                await postAccountDetails(data)
                await this.$auth.loadUserProfile()
            } catch (e) {
                this.snackbar = true
                this.errorMessage = `Unable to update account. Please try again later.`
            }
        }
    }
}
</script>

<style>
.hero-heading .pt-1 {
    font-size: 36px;
    letter-spacing: -0.1rem;
    left: 0;
    color: white;
    font-weight: 700;
    font-family: Roboto, sans-serif !important;
}
.hero-heading svg {
    display: none;
}
</style>
