var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-form',{ref:"form",model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=$$v},expression:"formValid"}},[_c('v-card',_vm._b({},'v-card',_vm.$attrs,false),[_c('v-card-title',[_c('div',{staticClass:"mr-auto"},[_vm._v("Information")]),_c('div',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-icon',_vm._g(_vm._b({on:{"click":_vm.cancel}},'v-icon',attrs,false),on),[_vm._v(_vm._s(_vm.mdiClose)+" ")])]}}])},[_c('span',[_vm._v("Cancel")])])],1)]),_c('v-card-text',[_c('v-text-field',_vm._b({attrs:{"value":_vm.sub,"label":"User ID","disabled":""}},'v-text-field',_vm.$attrs,false)),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',_vm._b({attrs:{"label":"First Name"},model:{value:(_vm.formInput.firstName),callback:function ($$v) {_vm.$set(_vm.formInput, "firstName", $$v)},expression:"formInput.firstName"}},'v-text-field',_vm.$attrs,false))],1),_c('v-col',{attrs:{"cols":"12","sm":"6"}},[_c('v-text-field',_vm._b({attrs:{"label":"Last Name"},model:{value:(_vm.formInput.lastName),callback:function ($$v) {_vm.$set(_vm.formInput, "lastName", $$v)},expression:"formInput.lastName"}},'v-text-field',_vm.$attrs,false))],1)],1),_c('v-text-field',_vm._b({attrs:{"label":"Company"},model:{value:(_vm.formInput.company),callback:function ($$v) {_vm.$set(_vm.formInput, "company", $$v)},expression:"formInput.company"}},'v-text-field',_vm.$attrs,false)),_c('v-text-field',_vm._b({attrs:{"label":"Email","rules":[_vm.formRules.email]},model:{value:(_vm.formInput.email),callback:function ($$v) {_vm.$set(_vm.formInput, "email", $$v)},expression:"formInput.email"}},'v-text-field',_vm.$attrs,false)),_c('base-tel-input',{ref:"phone",staticClass:"tele-input",attrs:{"id":"phone","valid-characters-only":"","dropdown-options":{
                    showDialCodeInSelection: true,
                    showFlags: true,
                    showDialCodeInList: true,
                    tabindex: 0
                },"default-country":_vm.userStoredContact.phoneCountryCode,"input-options":{
                    placeholder: 'Phone',
                    maxlength: 25,
                    autocomplete: 'on',
                    name: 'telephone',
                    required: false,
                    autofocus: false,
                    id: '',
                    readonly: false,
                    tabindex: 0,
                    type: 'tel',
                    styleClasses: ''
                }},on:{"update":_vm.assignPhone},model:{value:(_vm.phoneInput),callback:function ($$v) {_vm.phoneInput=$$v},expression:"phoneInput"}})],1),_c('div',{staticClass:"justify-end mb-4",class:_vm.$vuetify.breakpoint.smAndDown ? 'flex-column mx-4' : 'd-flex mr-4'},[_c('v-btn',_vm._b({class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3',attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"text":""},on:{"click":_vm.cancel}},'v-btn',_vm.$attrs,false),[_vm._v("Cancel")]),_c('div',[_c('v-btn',_vm._b({class:!_vm.$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3',attrs:{"block":_vm.$vuetify.breakpoint.smAndDown,"color":"primary","text":"","disabled":!_vm.phoneValid || !_vm.formValid},on:{"click":_vm.save}},'v-btn',_vm.$attrs,false),[_vm._v(" Save ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }