<template>
    <v-form ref="form" v-model="formValid">
        <v-card v-bind="$attrs">
            <v-card-title>
                <div class="mr-auto">Information</div>
                <div>
                    <v-tooltip bottom>
                        <template v-slot:activator="{ on, attrs }">
                            <v-icon v-bind="attrs" @click="cancel" v-on="on">{{ mdiClose }} </v-icon>
                        </template>
                        <span>Cancel</span>
                    </v-tooltip>
                </div>
            </v-card-title>

            <v-card-text>
                <v-text-field :value="sub" v-bind="$attrs" label="User ID" disabled />
                <v-row
                    ><v-col cols="12" sm="6">
                        <v-text-field v-model="formInput.firstName" v-bind="$attrs" label="First Name" />
                    </v-col>
                    <v-col cols="12" sm="6">
                        <v-text-field v-model="formInput.lastName" v-bind="$attrs" label="Last Name" />
                    </v-col>
                </v-row>
                <v-text-field v-model="formInput.company" v-bind="$attrs" label="Company" />
                <v-text-field v-model="formInput.email" v-bind="$attrs" label="Email" :rules="[formRules.email]" />
                <base-tel-input
                    id="phone"
                    ref="phone"
                    v-model="phoneInput"
                    class="tele-input"
                    valid-characters-only
                    :dropdown-options="{
                        showDialCodeInSelection: true,
                        showFlags: true,
                        showDialCodeInList: true,
                        tabindex: 0
                    }"
                    :default-country="userStoredContact.phoneCountryCode"
                    :input-options="{
                        placeholder: 'Phone',
                        maxlength: 25,
                        autocomplete: 'on',
                        name: 'telephone',
                        required: false,
                        autofocus: false,
                        id: '',
                        readonly: false,
                        tabindex: 0,
                        type: 'tel',
                        styleClasses: ''
                    }"
                    @update="assignPhone"
                />
            </v-card-text>
            <div class="justify-end mb-4" :class="$vuetify.breakpoint.smAndDown ? 'flex-column mx-4' : 'd-flex mr-4'">
                <v-btn
                    :block="$vuetify.breakpoint.smAndDown"
                    text
                    :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'"
                    v-bind="$attrs"
                    @click="cancel"
                    >Cancel</v-btn
                >
                <div>
                    <v-btn
                        :block="$vuetify.breakpoint.smAndDown"
                        :class="!$vuetify.breakpoint.smAndDown ? 'mr-2' : 'mb-2 px-3'"
                        color="primary"
                        text
                        :disabled="!phoneValid || !formValid"
                        v-bind="$attrs"
                        @click="save"
                    >
                        Save
                    </v-btn>
                </div>
            </div>
        </v-card>
    </v-form>
</template>

<script>
import { mdiClose } from '@mdi/js'
import formRules from '@/utils/form_rules'
export default {
    name: `AccountEditUserInfo`,
    inheritAttrs: false,
    props: {
        userStoredContact: {
            type: Object,
            default: () => {}
        },
        sub: {
            type: String,
            required: true
        }
    },

    data() {
        return {
            mdiClose,
            formRules,
            phone: {},
            phoneInput: ``,
            phoneValid: false,
            formValid: false,
            formInput: {
                company: ``,
                firstName: ``,
                lastName: ``,
                email: ``,
                phone: ``,
                phoneCountryCode: ``
            }
        }
    },
    mounted() {
        this.copyFromStore()
    },
    methods: {
        copyFromStore() {
            this.userStoredContact.firstName && (this.formInput.firstName = this.userStoredContact.firstName)
            this.userStoredContact.lastName && (this.formInput.lastName = this.userStoredContact.lastName)
            this.userStoredContact.email && (this.formInput.email = this.userStoredContact.email)
            this.userStoredContact.company && (this.formInput.company = this.userStoredContact.company)
            this.userStoredContact.phone && (this.phoneInput = this.userStoredContact.phone)
        },

        assignPhone(a) {
            // eslint-disable-next-line no-unused-vars
            const [input, phoneObject] = a
            this.phoneValid = phoneObject.nationalNumber ? !!phoneObject.valid : true
            this.formInput.phone = phoneObject.nationalNumber
            this.formInput.phoneCountryCode = phoneObject.countryCode
        },
        save() {
            if (this.formValid && this.phoneValid)
                this.$emit(`save`, {
                    firstName: this.formInput.firstName,
                    lastName: this.formInput.lastName,
                    email: this.formInput.email,
                    company: this.formInput.company,
                    phone: this.formInput.phone,
                    phoneCountryCode: this.formInput.phoneCountryCode
                })
        },
        cancel() {
            this.$emit(`cancel`)
        }
    }
}
</script>
